import { useStaticQuery, graphql } from "gatsby";

export const useTrendingPosts = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(filter: { frontmatter: { trending: { eq: true } } }, limit: 4) {
        nodes {
          fields {
            slug
          }
          excerpt(pruneLength: 160)
          frontmatter {
            title
            category
            author
            date(formatString: "MMMM DD, YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 768, quality: 90)
              }
            }
          }
        }
      }
    }
  `);

  return data.allMdx.nodes;
};
