import * as React from "react";

import Articles from "@components/Articles";
import { Typography, Wave } from "@components/atoms";

import { useLatestPosts } from "./Latest.hooks";

import { Container, SVG } from "./Latest.styles";

const Latest: React.FunctionComponent = () => {
  const posts = useLatestPosts();

  return (
    <Container>
      <Typography variant="h3">Latest Posts</Typography>
      <SVG width="35" height="6" xmlns="http://www.w3.org/2000/svg">
        <Wave />
      </SVG>
      <Articles items={posts} />
    </Container>
  );
};

export default Latest;
