import { useStaticQuery, graphql } from "gatsby";

export const useFeaturedPost = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(filter: { frontmatter: { featured: { eq: true } } }, limit: 1) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            category
            author
            date(formatString: "MMMM DD, YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 1024, quality: 90)
              }
            }
          }
        }
      }
    }
  `);

  return data.allMdx.nodes[0];
};
