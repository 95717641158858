import React from "react";

import Widgets from "@components/Widgets";
import Seo from "@components/Seo";
import Home from "@components/Home";

const IndexPage = () => {
  return (
    <>
      <Seo title="Home" />
      <Widgets>
        <Home />
      </Widgets>
    </>
  );
};

export default IndexPage;
