import * as React from "react";

import Articles from "@components/Articles";
import { Typography, Wave } from "@components/atoms";

import { useTrendingPosts } from "./Trending.hooks";

import { Container, SVG } from "./Trending.styles";

interface TrendingProps {}

const Trending: React.FunctionComponent<TrendingProps> = () => {
  const posts = useTrendingPosts();

  return (
    <Container>
      <Typography variant="h3">Editor's Pick</Typography>
      <SVG width="35" height="6" xmlns="http://www.w3.org/2000/svg">
        <Wave />
      </SVG>
      <Articles items={posts} />
    </Container>
  );
};

export default Trending;
