import * as React from "react";

import Featured from "./Featured";
import Trending from "./Trending";
import Latest from "./Latest";

import { Container } from "./Home.styles";

const Home: React.FunctionComponent = () => {
  return (
    <Container>
      <Featured />
      <Trending />
      <Latest />
    </Container>
  );
};

export default Home;
