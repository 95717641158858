import * as React from "react";
import { getImage } from "gatsby-plugin-image";

import { Typography } from "@components/atoms";

import { useFeaturedPost } from "./Featured.hooks";

import { Container, Author, Image, Content, Badge } from "./Featured.styles";

interface FeaturedProps {}

const Featured: React.FunctionComponent<FeaturedProps> = () => {
  const post = useFeaturedPost();

  if (!post) return null;

  return (
    <Container to={post.fields.slug}>
      <Image
        image={getImage(post.frontmatter.thumbnail)}
        alt={post.frontmatter.title}
      />
      <Content>
        <Badge>Featured</Badge>
        <Typography variant="h5">{post.frontmatter.title}</Typography>
        <Author>
          <span>{post.frontmatter.author}</span>
          <div />
          <span>{post.frontmatter.date}</span>
        </Author>
      </Content>
    </Container>
  );
};

export default Featured;
