import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const Container = styled(Link)`
  position: relative;
  border-radius: ${(props) => props.theme.utils.borderRadius};
  box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  overflow: hidden;
  margin-top: 24px;
  background: linear-gradient(#00000000, #10356561);

  @media (min-width: 1024px) {
    margin-top: 56px;
  }
`;

export const Image = styled(GatsbyImage)`
  z-index: -1;
`;

export const Content = styled.div`
  position: absolute;
  bottom: 16px;
  left: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    bottom: 32px;
    left: 32px;
    right: 32px;
  }

  h5 {
    text-shadow: 0 0 35px black;
    color: #ffffff;
  }
`;

export const Badge = styled.div`
  display: none;
  width: fit-content;
  background-color: ${(props) => props.theme.pallette.secondary.main};
  font-weight: 600;
  font-size: 12px;
  padding: 4px 12px;
  color: #ffffff;
  border-radius: ${(props) => props.theme.utils.borderRadius};
  box-shadow: ${(props) => props.theme.utils.boxShadowLight};
  margin-bottom: 8px;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0 0 35px black;
  }

  div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.pallette.secondary.main};
  }
`;
