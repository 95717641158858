import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 40px;
  }
`;
